const SupportPage = () => {
    return (
        <div>
            <h1>SupportPage</h1>
        </div>
    );
}

export {
    SupportPage
}