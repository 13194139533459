import styled from "styled-components";

export const DeployContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const LocalContainer = styled.div`

    width: 100%;
    height: 80%;
    background: red;
`;